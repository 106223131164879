import axios from "@/core/services/axios";
export const SET_MATCH_LIST = "SET_MATCH_LIST";

const state = {
  matchList: [],
};

const mutations = {
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
};

const actions = {
  fetchListData({ commit }, path = "/alien/admin/match") {
    return axios.get(path).then((response) => {
      commit(SET_MATCH_LIST, response.data.items);
      return response;
    });
  },
  getMatchInfo(_, payload) {
    return axios.get("/alien/match/" + payload);
  },
};

const getters = {
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
