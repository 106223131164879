import axios from "@/core/services/axios";
import _ from "lodash";
import {
  SET_LIST,
  CLEAR_LIST,
  SET_CURRENT,
  SET_ADVISORS_INFO,
  SET_INTERMEDIARY_LIST,
  SET_MATCH_LIST,
} from "./mutation-types";
import router from "@/router/clean";

const state = {
  list: [],
  current: {},
  advisorsInformation: [],
  intermediaryList: {
    type: "",
    list: [],
  },
  matchList: [],
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [CLEAR_LIST](state) {
    state.list = [];
  },
  [SET_CURRENT](state, current) {
    state.current = current;
  },
  [SET_ADVISORS_INFO](state, payload) {
    state.advisorsInformation = payload;
  },
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
};

const actions = {
  getSubmissionData({ commit, getters }, id) {
    const found = state.list.find((investee) => {
      return investee.submissionId === id;
    });
    if (found) {
      commit(SET_CURRENT, found);
      return Promise.resolve();
    }
    return axios
      .get(
        "/alien/admin/submissions/" +
          id +
          `?entity=${getters["getCurrentRole"]}`
      )
      .then((res) => {
        const submission = _.get(res, "data.items[0]");
        if (submission) {
          commit(SET_CURRENT, submission);
        }
      });
  },
  getListData({ commit }, path = "/alien/submissions") {
    return axios.get(path).then((res) => {
      commit(SET_LIST, res.data.items);
      return res;
    });
  },
  getReportData(_, path = "/alien/submissions") {
    return axios.get(path);
  },
  editSubmission({ state, getters }, payload) {
    var data = {
      ...state.current.data,
      submission: payload,
    };

    return axios.post(
      `/alien/admin/submissions?entity=${getters["getCurrentRole"]}`,
      {
        ...state.current,
        data,
      }
    );
  },
  editStatus({ state, commit, getters }, payload) {
    var data = {
      ...state.current.data,
      status: payload,
    };
    return axios
      .post(`/alien/admin/submissions?entity=${getters["getCurrentRole"]}`, {
        ...state.current,
        data,
      })
      .then((response) => commit(SET_CURRENT, response.data));
  },
  createAdvisorsObjects({ state, dispatch }, payload) {
    const submissionId = state?.current?.submissionId;
    return Promise.all(
      payload.map(async (item) => {
        if (
          !state.advisorsInformation.some((advisor) => advisor.type === item)
        ) {
          return await axios.post("/alien/advisors", {
            type: item,
            status: "shortlist",
            investeeSubmissionId: submissionId,
          });
        }
      })
    ).then(() => {
      dispatch("setAdvisorInformation", submissionId);
    });
  },
  setAdvisorInformation({ commit }, id) {
    return axios
      .get(`/alien/advisors?investeeSubmissionId=${id}`)
      .then((response) => {
        commit(SET_ADVISORS_INFO, response.data.items);
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    axios.patch("/alien/advisors/" + payload._id, payload).then(() => {
      dispatch("setAdvisorInformation", payload.investeeSubmissionId);
    });
  },
  setIntermediaryList({ commit }, payload) {
    return axios
      .get("/alien/available-intermediaries", { params: { type: payload } })
      .then((response) => {
        commit(SET_INTERMEDIARY_LIST, {
          list: response.data.items,
          type: payload,
        });
      });
  },
  setMatchList({ commit }, payload) {
    const submissionId = payload;
    return axios
      .get("/alien/matches", {
        params: { investeeSubmissionId: submissionId },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", payload.investeeSubmissionId);
    });
  },
  getMatchInfo(_, payload) {
    return axios.get("/alien/match/" + payload);
  },
  getInvesteeInformation(_, payload) {
    return axios.get("/alien/submissions/" + payload, {
      params: {
        entity: "investee",
      },
    });
  },
};

const getters = {
  getAll: (state) => {
    return state.list.map((item) => {
      return { ...item.data, _id: item._id, submissionId: item.submissionId };
    });
  },
  getById: (state) => {
    return state.current?.data;
  },
  currentSubmissionId: (state) => state.current?.submissionId,
  getCurrentRole: () => {
    const currentPath = router.currentRoute.value.fullPath;
    const subStrings = currentPath.split("/");
    return subStrings[1];
  },
  advisorsInformation: (state) => state.advisorsInformation,
  intermediaryListInfo: (state) => state.intermediaryList,
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
