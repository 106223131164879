import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import axios from "@/core/services/axios";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/user/user";
import TokensModule from "asokoinsight-tokens/src/vuex/tokens";
import investeeModule from "./modules/investees/investee";
import matchModule from "./modules/matches/matches";
import messageModule from "./modules/mesagges/message";
import filtersModule from "./modules/filters/filters";
import logsModule from "./modules/logs/logs";
import dashboardModule from "./modules/dashboard/dashboard";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    user: UserModule,
    tokens: TokensModule,
    list: investeeModule,
    message: messageModule,
    filters: filtersModule,
    logs: logsModule,
    dashboard: dashboardModule,
    match: matchModule,
  },
});

store.$http = axios;

export default store;
