import axios from "axios";
import config from "@/config/app.config";
import store from "@/store";
import router from "@/router/clean";
import _ from "lodash";

const baseURL = config.apiUrl;

const request = axios.create({
  baseURL,
  withCredentials: true,
});

request.interceptors.request.use(
  (conf) => {
    const token = store.getters["tokens/accessToken"];

    if (token) {
      conf.headers.Authorization = `Bearer ${token}`;
    }

    return conf;
  },

  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (_.get(error, "response.status") === 401) {
      if (store.getters["user/isLoggedIn"]) {
        store.dispatch("user/clearCurrentUser");
        router.push({ name: "sign-in" });
      } else {
        router.push({ name: "sign-in" });
      }
    }
    return Promise.reject(error);
  }
);

export default request;
