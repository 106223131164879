const SET_FILTERS = "SET_FILTER";
const RESET_FILTERS = "RESET_FILTERS";

const state = {
  status: "",
};

const mutations = {
  [SET_FILTERS](state, payload) {
    state.status = payload.status;
  },
  [RESET_FILTERS](state) {
    for (let ii in state) {
      state[ii] = "";
    }
  },
};

const actions = {
  setFilter({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
};

const getters = {
  getFilters: (state) => {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
