import axios from "@/core/services/axios";
import _ from "lodash";
import {
  getStatusName,
  getStatusObjectByName,
  investeeStatusMap,
  intermediaryStatusMap,
  investorStatusMap,
} from "asokoinsight-helpers-business-logic/src/dff-helpers";
import { matchStatusMap } from "asokoinsight-helpers-business-logic/src/dff-helpers/status-maps";

const SET_STATUS_COUNT = "SET_STATUS_COUNT";

const getChartData = (data, order, entity = "investee") => {
  const dataByStatus = {};
  for (let item in data) {
    const status = getStatusName(data[item]._id, entity);
    if (!status) {
      continue;
    }
    dataByStatus[status.name] = {
      name: status.label || status.name,
      chartColor: status.chartColor,
      count: data[item].count,
    };
  }

  const dataSet = order.map((item) => {
    let statusData = dataByStatus[item];
    if (!statusData) {
      statusData = getStatusObjectByName(item, entity);
    }
    return {
      x: statusData.name,
      y: statusData.count || 0,
      fillColor: statusData.chartColor,
    };
  });
  return dataSet;
};

const getStatusCounts = (state, entity = "investee") => {
  const response = {};

  const maps = {
    investee: investeeStatusMap,
    intermediary: intermediaryStatusMap,
    investor: investorStatusMap,
    match: matchStatusMap,
  };

  const map = maps[entity];

  for (let item in map) {
    const name = map[item].name;
    response[name] = { count: 0 };
  }

  const data = state.statusCount?.[entity];

  for (let item in data) {
    const status = getStatusName(data[item]._id, entity);
    if (!status) {
      continue;
    }
    response[status.name].count = data[item].count;
  }

  return response;
};

const state = {
  statusCount: {},
};

const mutations = {
  [SET_STATUS_COUNT](state, payload) {
    state.statusCount = payload;
  },
};

const actions = {
  getNumberOfSubmissionByStatus({ commit, dispatch }) {
    return axios
      .get("/alien/admin/submissions-status")
      .then((response) => {
        const matchData = _.get(response, "data.match[0]");
        let matches = [];
        if (matchData) {
          matches = Object.entries(response.data.match[0]).map((item) => {
            return {
              _id: item[0],
              count: item[1],
            };
          });
        }
        commit(SET_STATUS_COUNT, { ...response.data, match: matches });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "message/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Ok",
          },
          { root: true }
        );
      });
  },
};

const getters = {
  investeeCounts: (state) => {
    return getStatusCounts(state, "investee");
  },
  investorCounts: (state) => {
    return getStatusCounts(state, "investor");
  },
  intermediaryCounts: (state) => {
    return getStatusCounts(state, "intermediary");
  },
  matchesCounts: (state) => {
    return getStatusCounts(state, "match");
  },
  investeesChartInfo: (state) => {
    const actionsOrder = [
      "Onboarding",
      "Business review",
      "Deal preparation",
      "Investment",
    ];

    const data = state.statusCount?.investee || {};
    const dataSet = getChartData(data, actionsOrder, "investee");

    return { series: [{ name: "count", data: dataSet }] };
  },

  investorsChartInfo: (state) => {
    const actionsOrder = ["Review", "Onboarding", "Investment"];

    const data = state.statusCount?.investor || {};
    const dataSet = getChartData(data, actionsOrder, "investor");

    return { series: [{ name: "count", data: dataSet }] };
  },

  intermediariesChartInfo: (state) => {
    const actionsOrder = [
      "Review",
      "Onboarding",
      "Capacity Building",
      "Deal Support",
    ];

    const data = state.statusCount?.intermediary || {};
    const dataSet = getChartData(data, actionsOrder, "intermediary");

    return { series: [{ name: "count", data: dataSet }] };
  },

  matchesChartInfo: (state) => {
    const actionsOrder = ["interest", "talks", "exclusive"];

    const data = state.statusCount?.match || {};
    const dataSet = getChartData(data, actionsOrder, "match");

    return { series: [{ name: "count", data: dataSet }] };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
