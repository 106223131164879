import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/investees",
        name: "investees",
        component: () =>
          import(
            "@/custom-components/investees/investee-list/investee-list.vue"
          ),
      },
      {
        path: "/investee/:id",
        name: "investee",
        redirect: { name: "aplication" },
        component: () =>
          import(
            "@/custom-components/investees/investee-detail/investee-detail.vue"
          ),
        children: [
          {
            alias: "",
            path: "aplication",
            name: "aplication",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/form-submission.vue"
              ),
          },
          {
            path: "onboarding",
            name: "onboarding",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/onboarding-view.vue"
              ),
          },
          {
            path: "bdr",
            name: "bdr",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/bdr-intermediaries/bdr-intermediaries.vue"
              ),
          },
          {
            path: "potential",
            name: "potential",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/potential-investors/potential-investors.vue"
              ),
          },
          {
            path: "other",
            name: "other",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/unqualified-form.vue"
              ),
          },
          {
            path: "settings",
            name: "settings",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/investee-settings.vue"
              ),
          },
        ],
      },
      {
        path: "/investee/match-feedback/:feedbackId",
        name: "match-feedback",
        component: () =>
          import(
            "@/custom-components/investees/investee-detail/views/potential-investors/feed-back.vue"
          ),
      },
      {
        path: "/intermediaries",
        name: "intermediaries",
        component: () =>
          import(
            "@/custom-components/intermediary/intermediary-list/intermediary-list.vue"
          ),
      },
      {
        path: "/intermediary/:id",
        name: "intermediary",
        redirect: { name: "intermediary-aplication" },
        component: () =>
          import(
            "@/custom-components/intermediary/intermediary-detail/intermediary-detail.vue"
          ),
        children: [
          {
            alias: "",
            path: "",
            name: "intermediary-aplication",
            component: () =>
              import(
                "@/custom-components/intermediary/intermediary-detail/views/application-form.vue"
              ),
          },
        ],
      },
      {
        path: "/investors",
        name: "investors",
        component: () =>
          import(
            "@/custom-components/investor/investor-list/investor-list.vue"
          ),
      },
      {
        path: "/investor/:id",
        name: "investor",
        redirect: { name: "investor-aplication" },
        component: () =>
          import(
            "@/custom-components/investor/investor-detail/investor-detail.vue"
          ),
        children: [
          {
            alias: "",
            path: "",
            name: "investor-aplication",
            component: () =>
              import(
                "@/custom-components/investor/investor-detail/views/application-form.vue"
              ),
          },
        ],
      },
      {
        path: "/matches",
        name: "matches",
        component: () =>
          import("@/custom-components/matches/matches-list/matches-list.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/contact",
        name: "contact",
        component: () => import("@/custom-components/contact/contact.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
