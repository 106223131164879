
import { defineComponent, nextTick, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "app",
  setup() {
    useMeta({
      title: "",
    });
    const store = useStore();
    const isTokenChecked = computed(() => {
      return store.getters["tokens/isTokenChecked"];
    });

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      await store.dispatch("user/checkAndRefresh");

      nextTick(() => {
        initializeComponents();
      });
    });
    return {
      isTokenChecked,
    };
  },
});
