import Swal from "sweetalert2";
import { SET_MESSAGE, CLEAR_MESSAGE } from "./mutation-types";

const state = {
  text: "",
};

const mutations = {
  [SET_MESSAGE](state, payload) {
    state.text = payload;
  },
  [CLEAR_MESSAGE](state) {
    state.text = "";
  },
};

const actions = {
  showMessage({ commit }, payload) {
    const text =
      payload ||
      "Oops… there was an unexpected error. It has been reported. Please reload and try again.";
    commit(SET_MESSAGE, text);
  },
  hideMessage({ commit }) {
    commit(CLEAR_MESSAGE);
  },
  showSuccessMessage(_props, { text, icon, customClass, confirmButtonText }) {
    return Swal.fire({
      text: text,
      icon: icon || "success",
      buttonsStyling: false,
      confirmButtonText: confirmButtonText,
      customClass: customClass || {
        confirmButton: "btn fw-semobold btn-light-primary",
      },
    });
  },
  showErrorMessage(_props, { mainText, icon, customClass, buttonText }) {
    return Swal.fire({
      text: mainText,
      icon: icon || "error",
      buttonsStyling: false,
      confirmButtonText: buttonText,
      customClass: customClass || {
        confirmButton: "btn fw-semobold btn-light-danger",
      },
    });
  },
  showAlertMessage(_props, { mainText, icon, buttonText }) {
    return Swal.fire({
      text: mainText,
      icon: icon || "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: buttonText,
    });
  },
};

const getters = {
  getMessage: (state) => {
    return state.text;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
